import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [2,6,10];

export const dictionary = {
		"/(app)": [11,[2],[3]],
		"/(app)/admin": [12,[2,4],[3]],
		"/(app)/admin/ai-studio": [13,[2,4],[3]],
		"/(app)/admin/default-templates": [~14,[2,4],[3]],
		"/(app)/admin/default-text-templates": [~15,[2,4],[3]],
		"/(app)/admin/organisation": [~16,[2,4],[3]],
		"/(app)/admin/roles": [~17,[2,4],[3]],
		"/(app)/analytics": [18,[2],[3]],
		"/auth/login": [~43,[9]],
		"/auth/register": [44,[9]],
		"/auth/verify": [45,[9]],
		"/(app)/calendar": [~19,[2],[3]],
		"/(app)/calendar/[id]": [~20,[2],[3]],
		"/(app)/library": [~21,[2,5],[3]],
		"/(app)/library/collections": [~23,[2,5],[3]],
		"/(app)/library/[folder]": [22,[2,5],[3]],
		"/(app)/onboarding": [24,[2],[3]],
		"/(app)/profile": [~25,[2],[3]],
		"/(app)/reports": [~26,[2],[3]],
		"/(app)/scheduler": [27,[2,6],[3]],
		"/(app)/scheduler/[tab]": [~28,[2,6],[3]],
		"/(app)/settings": [29,[2,7],[3,8]],
		"/(app)/settings/billing": [~30,[2,7],[3,8]],
		"/(app)/settings/billing/success": [31,[2,7],[3,8]],
		"/(app)/settings/brand-profile": [~32,[2,7],[3,8]],
		"/(app)/settings/brands": [~33,[2,7],[3,8]],
		"/(app)/settings/image-templates": [~34,[2,7],[3,8]],
		"/(app)/settings/organisation": [~35,[2,7],[3,8]],
		"/(app)/settings/prompt-settings": [~36,[2,7],[3,8]],
		"/(app)/settings/roles": [~37,[2,7],[3,8]],
		"/(app)/settings/social-accounts": [~38,[2,7],[3,8]],
		"/(app)/settings/text-templates": [~39,[2,7],[3,8]],
		"/user/accept-invite": [~46,[10]],
		"/user/blank": [47,[10]],
		"/user/new": [~48,[10]],
		"/user/settings": [49,[10]],
		"/user/verify": [~50,[10]],
		"/(app)/workflows": [40,[2],[3]],
		"/(app)/workflows/[workflowId]": [~41,[2],[3]],
		"/(app)/workflows/[workflowId]/[taskId]": [~42,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';